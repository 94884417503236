






















































































































import { Component, Vue } from 'vue-property-decorator';
import { clearSession } from '@/lib/auth';
import { getComponent } from '@/utils/helpers';
import { SHOW_ALERT } from '@/lib/constants';

@Component({
  components: {
    AppSidebar: () => getComponent('common/AppSidebar'),
  },
})
export default class AppLayout extends Vue {
  isSidebarClosed = false;

  notificationsOpen = false;

  settingsOpen = false;

  showAlert = false;

  alertText = '';

  created() {
    this.$eventBus.$on(SHOW_ALERT, (text) => {
      this.showAlert = true;
      this.alertText = text;
    });
  }

  logout() {
    clearSession();
    this.$router.push({ name: 'Login' });
  }

  onSideBarClose() {
    this.isSidebarClosed = true;
  }

  toggleSidebar() {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    this.$refs.sidebar.toggleSidebarMenu();
    this.isSidebarClosed = false;
  }
}
