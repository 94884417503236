var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{attrs:{"id":"app_layout"}},[_c('section',{staticClass:"grid h-screen overflow-hidden",class:{
      'grid-flow-auto':  _vm.$route.name === 'Tasqs' && _vm.isSidebarClosed,
    }},[_c('app-sidebar',{ref:"sidebar",on:{"sidebarClose":_vm.onSideBarClose}}),_c('section',{staticClass:"overflow-y-scroll"},[_vm._t("header",[(_vm.$route.name != 'Map')?_c('header',{staticClass:"grid grid-flow-col items-center grid-cols-12 p-2 h-14 border-b\n          border-bordergray text-textgray display-none"},[_c('div',{staticClass:"grid grid-flow-col items-center col-span-11",class:{
              'justify-end': _vm.$route.name === 'Tasqs',
            }},[(_vm.isSidebarClosed)?_c('span',{staticClass:"material-icons cursor-pointer",class:{
                'hidden': _vm.$route.name === 'Map'
              },on:{"click":_vm.toggleSidebar}},[_vm._v(" list ")]):_vm._e(),_c('div',{staticClass:"items-center hidden px-2 space-x-2 md:flex-1 md:flex md:mr-auto md:ml-5",class:{
                 'relative': _vm.$route.name === 'Tasqs',
              }})]),_c('div',{staticClass:"grid col-span-1 items-center space-x-3"},[_c('div',[_c('div',{staticClass:"relative"},[_c('span',{staticClass:"grid items-center material-icons cursor-pointer",on:{"click":function($event){_vm.settingsOpen = !_vm.settingsOpen}}},[_vm._v(" more_vert ")]),(_vm.settingsOpen)?_c('div',{staticClass:"absolute w-40 mt-1 transform bg-white rounded-md right-0 translate-x-1\n                    min-w-max bg-charcoal shadow-lg rounded-md",on:{"click":function($event){_vm.settingsOpen = false}}},[_c('ul',{staticClass:"grid my-2"},[_c('li',[_c('a',{staticClass:"cursor-pointer block px-2 py-1 transition border-l-4 border-transparent\n                          hover:border-cyan hover:bg-gray-500",on:{"click":_vm.logout}},[_vm._v(" Logout ")])])])]):_vm._e()])])])]):_vm._e()]),_vm._t("default")],2)],1),_c('t-alert',{staticClass:"bottom-3 right-3",attrs:{"show":_vm.showAlert},on:{"hidden":function($event){_vm.showAlert = false}}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }